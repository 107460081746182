import Vue from 'vue'
import Router from 'vue-router'
import { registerGuard } from '@/router/Guard.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
        path: '/',
        redirect: '/my_portal',
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard (Contrat)
        {
          name: 'Dashboard',
          path: 'my_portal',
          meta: {
            translate: 'sidebar.my_portal', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['user'],
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Dashboard (Responsable)
        {
          name: 'DashboardResponsable',
          path: 'my_portal_responsable',
          meta: {
            translate: 'sidebar.my_portal', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['user'],
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Rapport
        {
          name: 'rapport',
          path: 'rapport',
          meta: {
            translate: 'sidebar.report', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['user'],
            requiresAuth: true,
          },
          component: () => import('@/views/rapport/Rapport'),
        },
        // Chart
        {
          name: 'DashboardChart',
          path: 'dashboard',
          meta: {
            translate: 'sidebar.dashboard', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/chart/Chart'),
        },
        // Profile
        {
          name: 'profile',
          path: 'profile',
          meta: {
            translate: 'sidebar.profile', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['user'],
            requiresAuth: true,
          },
          component: () => import('@/views/profile/paysafe/Profile'),
        },
        // Opération
        {
          name: 'operation-client',
          path: 'operation/client',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationClient'),
        },
        {
          name: 'operation-contrat',
          path: 'operation/contrat',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationContrat'),
        },
        {
          name: 'operation-commande',
          path: 'operation/commande',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationCommande'),
        },
        {
          name: 'operation-facture',
          path: 'operation/facture',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationFacture'),
        },
        {
          name: 'operation-tools',
          path: 'operation/tools',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationTools'),
        },
        // Autre
        {
          name: 'other-parameters',
          path: 'other/parameters',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationParameters'),
        },
        {
          name: 'other-messages',
          path: 'other/messages',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationMessages'),
        },
        {
          name: 'other-list',
          path: 'other/list',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationList'),
        },
        {
          name: 'other-toggles',
          path: 'other/toggles',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationToggles'),
        },
        {
          name: 'other-groupes',
          path: 'other/groupes',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationGroupes'),
        },
        {
          name: 'other-adminClients',
          path: 'other/adminclients',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationAdminClients'),
        },
        {
          name: 'other-devices',
          path: 'other/devices',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationDevices'),
        },
        // Users Management
        // {
        //   name: 'usersmanagement-users',
        //   path: 'usersmanagement/users',
        //   meta: {
        //     translate: 'sidebar.usermanagement', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
        //     roles: ['admin'],
        //     requiresAuth: true,
        //   },
        //   component: () => import('@/views/userManagement/enap/UsersManagementUsers'),
        // },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/pages/PagesHome'),
      children: [
        {
          name: 'Login',
          meta: {
            roles: ['*'],
          },
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'unauthorized',
          meta: {
            roles: ['*'],
          },
          path: 'unauthorized',
          component: () => import('@/views/pages/Unauthorized'),
        },
        {
          name: 'error500',
          meta: {
              roles: ['*'],
          },
          path: '/error500',
          component: () => import('@/views/pages/Error500'),
        },
      ],
    },
    // Not found route
    {
      path: '*',
      redirect: '/',
    },
  ],
  scrollBehavior () {
      return { x: 0, y: 0 }
  },
})

registerGuard(router)

// router.beforeEach((to, from, next) => {
//   // Si la personne n'est pas logger, on l'envoi sur la page de login.
//   if (!store.state.userInfo.info.firstName && to.path !== '/login') {
//     next('/login')
//   }
//   // On ne veux pas que le client revienne sur la page login si il est logger
//   if (to.path === '/login' && store.state.userInfo.info.firstName) {
//     next('/')
//   }

//   // If roles of routes is *, client can go in route
//   if (to.meta.roles.find(x => x === '*')) {
//     next()
//     return
//   }

//   const found = store.state.userInfo.info.roles.some(r => to.meta.roles.indexOf(r) >= 0)
//   if (found) {
//     next()
//   } else {
//     next('/unauthorized')
//   }
// })

export default router
